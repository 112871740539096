.random-verse-container {
  width: 100%;
  margin: 20px auto;
}

.verse-box {
  border: 2px solid #fd7e3e;
  border-radius: 5px;
  background: #fbefe9;
  padding: 15px;
}

.verse-text {
  margin: 0;
  font-size: 1.2rem;
  padding: 35px;
  font-weight: bold;
  text-align: center;
}

.night-mode .verse-box {
  border: 2px solid #cdafa0;
  background: #73625A;
}

.night-mode .verse-text {
  color: white;
}

@media screen and (max-width: 768px) {

  .verse-box {
    padding: 10px;
  }

  .verse-text {
    font-size: 1rem;
  }
}
