/* Verse.css */
.verse-container {
  width: 90%;
  margin: 20px auto;
}

.box {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background: #f5e0d6;
  border: 2px solid #fd7e3e;
  text-align: start;
}

.header-chapter-number {
  text-align: start;
  margin-left: 60px;
  color: #353239;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-chapter-summary-hindi {
  text-align: start;
  margin-left: 60px;
  margin-right: 45px;
}

.header-chapter-summary {
  text-align: start;
  margin-left: 60px;
  margin-right: 45px;
}

.more {
  text-align: end;
  font-size: 14px;
  margin-right: 70px;
  cursor: pointer;
}

.translation {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background: #efb599;
  border: 2px solid #fd7e3e;
}

.translate {
    text-align: end;
    margin-right: 10px;
    cursor: pointer;
}

.translation-description {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background: #e9e9e9;
  border: 2px solid #bb9b8b;
}

.expanded-content {
  display: none;
}

.expanded-content.visible {
  display: block;
  margin-top: 10px;
}

.translation-list {
  list-style-type: none;
  padding: 0;
}

.translation-item {
  margin-bottom: 10px;
}

.title {
  text-align: center;
  font-style: italic;
}

.description {
  text-align: start;
}

.author {
  text-align: end;
}

.night-mode .header-chapter-number, .night-mode .header-chapter-summary, .night-mode .header-chapter-summary-hindi, .night-mode .box p, .night-mode .box h4, .night-mode .translate {
  color: white;
}


.night-mode .box {
  border: 2px solid #cdafa0;
  background: #73625a;
}

@media screen and (max-width: 768px) {
  .header-chapter-number {
    margin-left: 15px;
  }

  .header-chapter-summary-hindi {
    margin-left: 15px;
  }

  .header-chapter-summary {
    margin-left: 15px;
  }

  .more {
    margin-right: 20px;
  }
}
