.hanuman-chalisa-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hanuman-chalisa-container h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.hanuman-chalisa-container p {
  font-size: 1.2rem;
  line-height: 1.6;
}

.tab-navigation {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  background: #b38872;
}

.tab {
  padding: 10px;
  cursor: pointer;
  text-align: center;
  flex: 1;
}

.tab.active {
  background-color: #fd7e3e;
}

.chalisa-text {
  border: 1px solid #ccc;
  padding: 10px;
  max-width: 100%;
  overflow: auto;
}
