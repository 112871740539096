/* Verse.css */
.verse-container {
  width: 90%;
  margin-bottom: 20px;
  overflow: hidden; /* Ensure no overflow */
}

.box {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background: #f5e0d6;
  border: 2px solid #fd7e3e;
  text-align: start;
}

.verse {
  text-align: justify;
  padding: 20px;
}

.verses {
  text-align: justify;
}

.translation-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background: #f5e0d6;
  border: 2px solid #fd7e3e;
  text-align: start;
}

.header-chapter-number {
  text-align: start;
  margin-left: 60px;
  color: #353239;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-chapter-summary-hindi {
  text-align: start;
  margin-left: 60px;
  margin-right: 45px;
}

.header-chapter-summary {
  text-align: start;
  margin-left: 60px;
  margin-right: 45px;
}

.more {
  text-align: end;
  font-size: 14px;
  margin-right: 70px;
  cursor: pointer;
}

.translation {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background: #c9c0bc;
  border: 2px solid #fd7e3e;
  display: none;
}

.translations {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background: #efb599;
  border: 2px solid #fd7e3e;
}

.translate {
  text-align: end;
  margin-right: 10px;
  cursor: pointer;
}

.translation-description {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background: #e9e9e9;
  border: 2px solid #bb9b8b;
}

.translation.visible {
  display: block;
  margin-top: 10px;
}

.translation-list {
  list-style-type: none;
  padding: 0;
}

.translation-item {
  margin-bottom: 10px;
}

.title {
  text-align: center;
  font-style: italic;
}

.description {
  text-align: start;
}

.author {
  text-align: start;
}

.night-mode .verse-container h2,
.night-mode .verse {
  color: white;
}

.night-mode .translation-row {
  border: 2px solid #cdafa0;
  background: #73625a;
}

.arrow-icons {
    color: #fd7e3e;
}

.night-mode .arrow-icons {
    color: white;
}

.night-mode .translation-row p {
    color: white;
}

@media screen and (max-width: 768px) {
  .header-chapter-number {
    margin-left: 15px;
  }

  .header-chapter-summary-hindi {
    margin-left: 15px;
  }

  .header-chapter-summary {
    margin-left: 15px;
  }

  .more {
    margin-right: 20px;
  }
}
